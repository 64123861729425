import React from 'react'
import FadeInSection from './UI/FadeComp';
import { Nav, } from 'react-bootstrap';

function FirstSection() {
    return (
        <>
            <div>
                <FadeInSection>
                    <div alt="waterfall" className='firstImg' />
                    <div className='hero-text'>
                        <h2 className='title'>
                            Ambitus Strategy
                        </h2>
                        <div className='hero-button1 nest-button white' href="#About">
                            <Nav.Link title="Documentation" href="#Contact" >
                                Contacte-nos
                            </Nav.Link>
                        </div>
                        <div className='hero-button2 nest-button white'>
                            <Nav.Link title="Documentation" href="#Products" >
                                Os nossos sistemas
                            </Nav.Link>
                        </div>
                    </div>
                </FadeInSection>
            </div>
        </>
    )
}
export default FirstSection;