import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import FadeInSection from './UI/FadeComp';



function About() {
  return (
    <>
      <FadeInSection>
      
          <Container  id='Advantages'>
          <Container>
            <p className='title text-center pdImg'>
                Principais Vantagens
            </p>
            </Container>
            <Container>
                <p className='mt-5'>
                <b>Reutilização Eficiente: </b>Os equipamentos são concebidos para reaproveitar pelo menos 80% da água utilizada, promovendo uma economia substancial nos custos operacionais relacionados ao consumo de água.
                </p>
                <p>
                <b>Tecnologia Avançada:</b> Utilizamos oxidação catalítica heterogénea avançada, uma tecnologia de ponta que garante a purificação e reutilização eficiente da água, atendendo aos mais altos padrões de qualidade.
                </p>
                <p>
                <b>Equipamentos Modulares:</b> Os nossos sistemas são modulares, permitindo fácil adaptação e integração em qualquer tipo de indústria, independentemente do tamanho ou setor. Isto garante uma implementação flexível e personalizada de acordo com as necessidades específicas de cada cliente.
                </p>
                <p>
                <b> Monitorização em Tempo Real:</b> Todos os nossos equipamentos estão equipados com sensores que monitorizam a qualidade da água em tempo real, garantindo a eficácia e segurança do processo de reutilização.
                </p>
                <p>
                <b>Manutenção Remota:</b> A manutenção de todos os nossos equipamentos é feita remotamente, proporcionando conveniência e rapidez na resolução de qualquer questão técnica, minimizando interrupções no processo industrial.
                </p>
         
            </Container>
         
          
       
            </Container>
          

          

     
      </FadeInSection>





    </>
  )
}
export default About;