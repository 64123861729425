import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/Logo_Ambitus.png'
import { CgFileDocument } from "react-icons/cg";
import { useState } from 'react';
import { Modal} from 'react-bootstrap';


function Navbars() {


  return (
    <>
    <Navbar  expand="lg" variant="light" className='nav_custom'  sticky='top'>
      <Container >
        <Navbar.Brand href="#">
          <img src={logo} alt='logo'
            className='adjust-img1'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 "
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link className='navlinkNormal white' href="#About">Sobre nós</Nav.Link>
            <Nav.Link className='navlinkNormal white' href="#Products">Produtos</Nav.Link>
            <Nav.Link className='navlinkNormal white' href="#Aplications">Aplicações</Nav.Link>
            <Nav.Link className='navlinkNormal white' href="#Advantages">Vantagens</Nav.Link>
          </Nav>
      
          <div className='nest-button '>
          <Nav.Link title="Documentation"  href="#Contact" >
            Contatos
            </Nav.Link>
          </div>
        </Navbar.Collapse>
    
      </Container>
      
    </Navbar>
   

                     
    </>
  );
}

export default Navbars;