
import React from 'react'
import { Container } from 'react-bootstrap'
import axios from "axios";
import { useState } from 'react';
import logo from '../assets/images/Logo_Ambitus.png'


export default function ContactForm() {
   const APIKEY = process.env.REACT_APP_APIKEY;

   const [newsEmail, setNewsEmail] = useState("")
   const [msg, setMsg] = useState("")

   const url= "https://api.xcrow.net/notifications/mailchimp/member_add"

    const headers = {
      headers: { "Content-Type": "application/json", "x-api-key": "D4hWWPQo9v5zkj7cINqUX8KFpANqkuYW9ZtTrNXE"},
    };
    const data = [newsEmail, msg];
    

  const onChangeHandler = event => {
    setNewsEmail(event.target.value);
    
  }
  const onChangeHandler1 = event => {
    setMsg(event.target.value);
    
  }

  const onsubmitHandler =  async event => {
    event.preventDefault();
    if (newsEmail === "") {
      return;
    }
    if (newsEmail === "") {
        return;
      }
    event.target.value = ""
    
    axios.post(url,data,headers)
    .catch((error) => {
      console.log(error);
    });
    
      const thanksText = document.querySelector("#thanks")
      const btn = document.querySelectorAll("#submitButton")
      thanksText.innerText += "Mensagem Enviada"
      btn[0].disabled = true
  
      setTimeout(() => {
        thanksText.innerText = ""
        document.querySelector("submitButton")
        btn[0].disabled = false
  
      }, "5000")

    };
    
      
   
  return (
    <>
     
   
    
          <Container className="text-center mt-5 bigContainer">
            <img src={logo} className='logo'/>
            <h3 className='newsSubTitle'>Contacte-nos</h3>

            <form onSubmit={onsubmitHandler}>
              <input className='emailInput '
                type="email"
                placeholder='Email'
                onChange={onChangeHandler}
                value={newsEmail}
              />
              <br/>
               <label>Mensagem: </label>
               <br></br>
              <input className='textInput'
                type="textarea"
                placeholder='Mensagem'
                onChange={onChangeHandler1}
                value={msg}
              />
              <br />
              <button type="submit" id='submitButton' className="formButton"> Submeter </button>
              <br />
              <p id='thanks' className='thanksText mt-5'></p>
            </form>
   

        </Container>


   

      
    </>

  )
}