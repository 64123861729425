import React from 'react'
import FadeInSection from './UI/FadeComp';
import { Container, Row, Col } from 'react-bootstrap';
import Hospital from '../assets/images/hospital.webp'
import Laundromat from '../assets/images/laundromat.webp'
import Carwash from '../assets/images/carwash.webp'
import Vineyard from '../assets/images/vineyards.webp'
import Condominum from '../assets/images/Condominium.webp'
import Industry from '../assets/images/industry.webp'

function Applications() {
    return (
        <>
            <div className=' space-10'>
                <FadeInSection>
                    <Container className='space-10 grid page' id='Aplications'>
                        <p className='title text-center '>
                            Casos de Uso
                        </p>
                        <FadeInSection>
                        <Row className='mt-5 rowXMobile text-center'>
                            <Col >
                                <img src={Condominum} className="roundImg" alt="hospital hallway" />
                                <p className='text-center subtitle space-5'>Condominios</p>
                            </Col>
                            <Col >
                                <img src={Laundromat} className="roundImg" alt="hospital hallway" />
                                <p className='text-center subtitle space-5'>Lavandarias</p>
                            </Col>
                            <Col >
                                <img src={Hospital} className="roundImg" alt="hospital hallway" />
                                <p className='text-center subtitle space-5'>Hospitais</p>
                            </Col>
                        </Row>
                        <FadeInSection/>
                        </FadeInSection>
                        <FadeInSection>
                            <FadeInSection>
                        <Row className='space-10 rowXMobile text-center pdb1'>
                        <Col >
                                <img src={Industry} className="roundImg" alt="hospital hallway" />
                                <p className='text-center subtitle space-5'>Industrias</p>
                            </Col>
                            <Col >
                                <img src={Carwash} className="roundImg" alt="hospital hallway" />
                                <p className='text-center subtitle space-5'>Car Washes</p>
                            </Col>
                            <Col >
                                <img src={Vineyard} className="roundImg" alt="hospital hallway" />
                                <p className='text-center subtitle space-5'>Agricultura</p>
                            </Col>
                            
                        </Row>
                        </FadeInSection>
                        </FadeInSection>
                            </Container>
                    

                </FadeInSection>

            </div>

        </>
    )
}
export default Applications;