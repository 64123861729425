import React from 'react'
import FadeInSection from './UI/FadeComp';
import { Container, Row, Col } from 'react-bootstrap';
import System1 from '../assets/images/sistema1.JPG'
import System2 from '../assets/images/sistema2.JPG'
import Sytem3 from '../assets/images/Sytem3.1.jpg'

function Applications() {
    return (
        <>
            <div cl>
                <FadeInSection>
                    <Container className='space-10 page' id='Products'>
                        <p className='title text-center '>
                            Os nossos sistemas
                        </p>
                        <FadeInSection>
                            <Container>
                                <Row className='space-10 '>
                                    <Col >
                                        <img src={System1} className="imgEquip" alt="hospital hallway" />
                                      
                                    </Col>
                                    <Col >
                                        <p className='center-row text_eq'>
                                        Sistema industrial de oxidação catalítica avançada heterogénea com polimento de osmose reversa é uma solução de ponta projetada para tratamento de água em grande escala, com capacidade de até 15 m³/h.
                                         Este sistema é ideal para indústrias que requerem um tratamento eficiente e confiável da água para diversos processos, garantindo alta qualidade e sustentabilidade.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </FadeInSection>
                        <FadeInSection>
                            <Container fluid="sm">
                                <Row className='space-10 text-center'>
                                    <Col className='no_see' >
                                        <p className='center-row text_eq '>
                                        Com energia dielétrica (DC), a água é ionizada e o oxigénio é separado da água, gerando ozono (O3).
                                        O Reator de Oxidação Catalítica é composto por uma série de células catalíticas dispostas em tanques de contato, confeccionados em PRFV, com entrada e saída conectadas ao circuito hidráulico do efluente a ser tratado.

                                        </p>
                                    </Col>
                                    <Col >
                                        <img src={System2} className="imgEquip" alt="hospital hallway" />
                                     
                                    </Col>
                                    <Col className='hidden'>
                                    <p className='center-row text_eq '>
                                        Com energia dielétrica (DC), a água é ionizada e o oxigénio é separado da água, gerando ozono (O3).
                                        O Reator de Oxidação Catalítica é composto por uma série de células catalíticas dispostas em tanques de contato, confeccionados em PRFV, com entrada e saída conectadas ao circuito hidráulico do efluente a ser tratado.

                                        </p>
                                    </Col>
                                </Row>
                            </Container>

                            <Container fluid="sm">
                                <Row className='space-10  text-center'>
                                    <Col >
                                        <img src={Sytem3} className="imgEquip1" alt="hospital hallway" />
                                        
                                    </Col>
                                    <Col >
                                        <p className='center-row text_eq'>
                                        Equipamento de oxidação catalítica avançada, é um sistema de tratamento de água de alta eficiência projetado para aplicações industriais com capacidade até 1 m³/h, como em instalações de lavagem a jato como no caso (jetwash). Este sistema é ideal para a remoção de contaminantes orgânicos e inorgânicos, oferecendo uma solução sustentável e econômica para a reutilização da água.
                                        </p>
                                    </Col>
                                </Row>

                            </Container>
                        </FadeInSection>
                    </Container>
                    {/* <Container>
                    <video width="80%" controls class="videoSection">
                        <source src="./assets/ExplainerVideo.mp4" type="video/mp4"/>
                        </video>
                    </Container> */}


                </FadeInSection>

            </div>

        </>
    )
}
export default Applications;