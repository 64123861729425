import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import FadeInSection from './UI/FadeComp';
import Globe from '../assets/images/icons_globe.png'
import Leaf from '../assets/images/icons_leaf.png'
import Partner from '../assets/images/icons_partnership.png'
import Reuse from '../assets/images/icons_reuse.png'
import Water from '../assets/images/icons_water.png'
import WaterFlow from '../assets/images/waterflow.webp'


function About() {
  return (
    <>
      <FadeInSection>
      
          <Container  id='About'>
          <Container>
            <p className='subtitle1 text-center pdImg'>
              A AMBITUS STRATEGY  é uma empresa especializada em tratamento de efluentes e no desenvolvimento e monitorização de sistemas de reutilização de águas residuais urbanas, industriais e pluviais contaminadas.
            </p>
            </Container>
         
          
          <div className='space-5'>
            
            <Container>
              <Row>
                <Col className='text-center '>
                <div className='mobileBlock'>
                  <img src={Water} className="icon1 " alt="demo of XCROW app" />
                  <h3 className='space-5'> <b>Tecnologia Avançada</b></h3>
                  Os nossos sistemas são projetados com precisão e inovação, empregando os mais recentes avanços em engenharia ambiental para proporcionar soluções eficazes e eficientes. 
                  </div>
                </Col >
                <Col className='text-center'>
                <div className='mobileBlock'>
                  <img src={Reuse} className="icon1" alt="demo of XCROW app" />
                  <h3 className='space-5'><b>Soluções Sustentáveis</b></h3>
                  Estamos comprometidos em desenvolver sistemas que minimizem o impacto ambiental e promovam a conservação dos recursos hídricos.
                   Utilizamos materiais e processos ecológicos, garantindo que nossas práticas estejam alinhadas com os mais altos padrões de sustentabilidade. 
                   </div>
                </Col>
                <Col className='text-center'>
                <div className='mobileBlock'>
                  <img src={Globe} className="icon1" alt="demo of XCROW app" />
                  <h3 className='space-5'><b>Impacto global</b></h3>
                  Estamos comprometidos em causar um impacto positivo no mundo.
                   A Ambitus Strategy acredita que a reutilização da água é essencial para enfrentar os desafios globais de escassez de recursos e mudanças climáticas. 
                   </div>
                </Col>
              </Row>
              <Row className='space-10'>
                <Col className='text-center'>
                <div className='mobileBlock'>
                  <img src={Partner} className="icon1" alt="demo of XCROW app" />
                  <h3 className='space-5'><b>Parcerias Estratégicas</b></h3>
                  Na Ambitus Strategy, colaboramos com laboratórios especializados para realizar análises rigorosas da qualidade da água tratada.
                   Essas parcerias garantem que nossos sistemas de reutilização atendam aos mais altos padrões de segurança e pureza. 
                   </div>
                </Col>
                <Col className='text-center'>
                <div className='mobileBlock'>
                  <img src={Leaf} className="icon1" alt="demo of XCROW app" />
                  <h3 className='space-5'><b>Eco-Friendly</b></h3>
                  Práticas ambientalmente responsáveis são o coração da Ambitus Strategy. Os nossos sistemas são projetados para reduzir o consumo de energia e minimizar a emissão de poluentes, 
                  promovendo um ciclo de reutilização da água que é amigável ao meio ambiente. Com a Ambitus Strategy, 
                   a sustentabilidade não é apenas um objetivo, é uma prática diária.
                   </div>
                </Col>
              </Row>
            </Container>
          
            
          
         
          </div>
          </Container>
      </FadeInSection>





    </>
  )
}
export default About;