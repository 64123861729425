import React from 'react';
import logo from '../assets/images/Logo_Ambitus.png'
import { Container, Col, Row } from 'react-bootstrap';

export default function Footer() {
  let newDate = new Date()
  let year = newDate.getFullYear();
  return (
<div className='footer_nav space-10'>
    <Container  className=' text-muted'  id ="Contact" >
        <Container className='pd1'>
          <Row className='mt-5'>
            <Col md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='footer-title '>
                <img src={logo} alt="logo Ambitus" width={"50px"}/>
               Ambitus Strategy
              </h6>
              <p className='white '>
                <i>
              Transformando Água Residual em um Futuro Sustentável
              </i>
              </p>
            </Col>


            <Col md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='footer-title space-5'>Links uteís</h6>
           
                {/* <a href='https://drive.google.com/file/d/16ZsziAvQJDOoilZ9O-7nJKVe7QY4HJR2/view' className='footer-text white' target='_blank' rel="noreferrer">
                  Vídeos
                </a> */}
            
              <p>
                <a href='https://apambiente.pt/agua/agua-para-reutilizacao-apr' className='footer-text white'  target='_blank' rel="noreferrer">
                  Legislação
                </a>
              </p>
              <p>
           
              </p>
             
            </Col>

            <Col md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-5'>
              <h6 className='footer-title space-5'>Contactos</h6>
              <p className="dark-text">
              
                <p className='space-5 white'>
                  <a href='tel:+351925460087' className='a-none'>
                +351925460087
                </a>
                </p>
              </p>
            </Col>
          </Row>
        </Container>
    
      <div className='text-center p-4 black-text' >
        ©{year} Copyright Ambitus Strategy, LDA
     
      </div>
      </Container>
      </div>
  );
}