
import './index.css';
import Navbars from './components/Navbars';
import About from './components/About';
import React from 'react';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm'
import FirstSection from './components/FirstSection';
import Applications from './components/Applications';
import Equipments from './components/Equipments'
import Advantages from './components/Advantages'


function App() {

  
  return (


    <div className="App">
      <Navbars />
      <FirstSection/>
      <About />
      <Equipments/>
      <Advantages/>
      <Applications/>
      {/* <Team /> */}
      <ContactForm/>
     <Footer/>
    </div>
  );
}

export default App;
